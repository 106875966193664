import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name : 'commonReducer',
    initialState : {
        value: true,
        
    },
    reducers : {
        updateRedux: (state , data) => {
            state[data.payload.key ] = data.payload.value
        },
    },
})

export const { updateRedux } = commonSlice.actions;

export const value = (state ) => state.commonReducer.value;
export const banner_lists = (state ) => state.commonReducer.banner_lists;
export const visionMision_lists = (state ) => state.commonReducer.visionMision_lists;
export const getHome_lists = (state ) => state.commonReducer.getHome_lists;
export const rolesAssign_lists = (state ) => state.commonReducer.rolesAssign_lists;
export const About_details = (state ) => state.commonReducer.About_details;
export const AboutValues_details = (state ) => state.commonReducer.AboutValues_details;
export const contactInfo_details = (state ) => state.commonReducer.contactInfo_details;
export const jurisdictionAuthorities_details = (state ) => state.commonReducer.jurisdictionAuthorities_details;

export default commonSlice.reducer;