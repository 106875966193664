import React, { useState } from 'react'
import '../styles/ReportIncident.scss'
import Header from '../components/Header'
import Page1 from '../components/ReportIncident/Page1'
import Page2 from '../components/ReportIncident/Page2'
import Page3 from '../components/ReportIncident/Page3'
import Page4 from '../components/ReportIncident/Page4'
import ThanksPage from '../components/ReportIncident/ThanksPage'
import UpdatesPage from '../components/ReportIncident/UpdatesPage'
import { useTranslation } from 'react-i18next';
import LinkExpired from '../components/ReportIncident/LinkExpired'

export default function ReportIncident() {

    const [stage, setStage] = useState(1)
    const [inputs, setInputs] = useState({ files: [], country_code: '+971' })
    const [inputErrors, setInputErrors] = useState({})

    const { t } = useTranslation();


    // console.log({ inputs })
    // console.log({ inputErrors })
    return (
        <div className='Report-incident'>
            <section className='banner container'>
                <img src='./assets/img/banner/2.jpg' className='w-100' />
                <Header />
                {
                    stage === 6 ?
                        <div className="banner-content">
                            <h1>Incident Report</h1>
                            <p className='incident-number'>Incident No : ESCD23001</p>
                        </div>
                        : stage === 7 ? null
                            :
                            <div className='banner-content'>
                                <h1>{t('Incident Report')}</h1>
                                <div className='form-page-progress'>
                                    <span className='stages'>
                                        <i className={stage >= 1 ? 'done' : ''} onClick={() => stage > 1 && setStage(1)}>1</i><p>Introduction</p>
                                    </span>
                                    <span className={`progress-line ${stage >= 2 ? ' done' : ''}`}></span>
                                    <span className='stages'>
                                        <i className={stage >= 2 ? 'done' : ''} onClick={() => stage > 2 && setStage(2)}>2</i><p>Personal Information</p>
                                    </span>
                                    <span className={`progress-line ${stage >= 3 ? ' done' : ''}`}></span>
                                    <span className='stages'>
                                        <i className={stage >= 3 ? 'done' : ''} onClick={() => stage > 3 && setStage(3)}>3</i><p>Incident Report</p>
                                    </span>
                                    <span className={`progress-line ${stage >= 4 ? ' done' : ''}`} ></span>
                                    <span className='stages'>
                                        <i className={stage >= 4 ? 'done' : ''} onClick={() => setStage(4)}>4</i><p>Review and submit</p>
                                    </span>
                                </div>
                            </div>

                }
            </section>
            <section className='pages'>
                {
                    stage === 1 ? <Page1 setStage={setStage} setInputs={setInputs} inputs={inputs} /> :
                        stage === 2 ? <Page2 setStage={setStage} setInputs={setInputs} inputs={inputs} setInputErrors={setInputErrors} inputErrors={inputErrors} /> :
                            stage === 3 ? <Page3 setStage={setStage} setInputs={setInputs} inputs={inputs} setInputErrors={setInputErrors} inputErrors={inputErrors} /> :
                                stage === 4 ? <Page4 setStage={setStage} setInputs={setInputs} inputs={inputs} /> :
                                    stage === 5 ? <ThanksPage /> :
                                        stage === 6 ? <UpdatesPage /> :
                                            stage === 7 ? <LinkExpired /> :
                                                null
                }
            </section>
            <footer className='container'>
                <div className='social-media'>
                    <ul>
                        <li><a href="https://www.instagram.com/escdubai/" target='_blank'><i className='fab fa-instagram'></i></a></li>
                        <li><a href="https://twitter.com/escdubai"><i className='fab fa-twitter' target='_blank'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/escdubai/"><i className='fab fa-linkedin-in' target='_blank'></i></a></li>
                        <li><a href="https://www.facebook.com/ESCODXB"><i className='fab fa-facebook-f' target='_blank'></i></a></li>
                    </ul>
                </div>
                <div className='copyright'>
                    <p>Copyright 2023, Economic Security Center of Dubai</p>
                </div>
            </footer>

        </div>
    )
}
