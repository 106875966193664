import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/notfound.css'

function NotFound() {
  return (
    <section className="page-section">
      <div className="full-width-screen">
        <div className="container-fluid p-0">
          <div className="content-detail p-0">
            <div className="container-txt p-0">
              <h1><span>404</span></h1>
              <div className="blobs_1" />
              <div className="blobs_2" />
              <div className="blobs_3" />
              <div className="blobs_4" />
              <div className="blobs_5" />
              <div className="blobs_6" />
              <div className="blobs_7" />
            </div>
            <h4 className="sub-title">Oops!</h4>
            <p className="detail-text">We're sorry,<br /> The page you were looking for doesn't exist anymore.</p>
            <Link to="/"><button className="btn btn-primary" style={{width:"165px",padding:"10px"}}>Back to Home</button></Link>
          </div>
        </div>
      </div>
    </section>

  )
}

export default NotFound