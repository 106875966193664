import React, { useState, useEffect } from 'react'
import { onNextButtonValidation } from '../../utils/validation'

export default function Page3({ setStage, setInputs, inputs, setInputErrors, inputErrors }) {

    function onNextButtonHandle() {
        const isValidated = onNextButtonValidation({
            incident_type: inputs.incident_type,
            entity: inputs.entity,
            date: inputs.date,
            reported_elsewhere: inputs.reported_elsewhere,
            description: inputs.description
        }, setInputErrors)

        console.log({ isValidated })
    }

    return (
        <div className='Page3 container'>
            <h2>Please fill in the details of the incident</h2>
            <div className='inputs'>
                <div className='input-group'>
                    {inputErrors.incident_type && <p>{inputErrors.incident_type}</p>}
                    <select type="text" placeholder='Incident Type*' className={(inputErrors.incident_type ? 'error' : '')}
                        onChange={(e) => (setInputs((s) => ({ ...s, incident_type: e.target.value })), setInputErrors({}))}>
                        <option value="" selected hidden>Incident Type*</option>
                        <option value="">option 1</option>
                        <option value="">option 2</option>
                        <option value="">option 3</option>
                    </select>
                </div>
                <div className='input-group'>
                    {inputErrors.entity && <p>{inputErrors.entity}</p>}
                    <input type="text" placeholder='Name of Entity*' className={(inputErrors.entity ? 'error' : '')}
                        onChange={(e) => (setInputs((s) => ({ ...s, entity: e.target.value })), setInputErrors({}))} />
                </div>
                <div className='input-group'>
                    {inputErrors.date && <p>{inputErrors.date}</p>}
                    <input type="text" placeholder='Date of Incident' onChange={(e) => (setInputs((s) => ({ ...s, date: e.target.value })), setInputErrors({}))}
                        onFocus={(e) => e.currentTarget.type = 'date'} onBlur={(e) => e.currentTarget.type = 'text'}
                    />
                </div>
                <div className='input-group'>
                    {inputErrors.reported_elsewhere && <p>{inputErrors.reported_elsewhere}</p>}
                    <div className='radio-question'>
                        Is this incident reported elsewhere?*
                        <div className='labels'>
                            <label htmlFor="">
                                Yes
                                <input type="radio" name="incident-prev-status" id="" value='yes'
                                    onChange={(e) => (setInputs((s) => ({ ...s, reported_elsewhere: e.target.value })), setInputErrors({}))} />
                            </label>
                            <label htmlFor="">
                                No
                                <input type="radio" name="incident-prev-status" id="" value='no'
                                    onChange={(e) => (setInputs((s) => ({ ...s, reported_elsewhere: e.target.value })), setInputErrors({}))} />
                            </label>
                        </div>
                    </div>
                </div>
                <div className='input-group textarea'>
                    {inputErrors.description && <p>{inputErrors.description}</p>}
                    <textarea type="text" placeholder='Description*' maxLength={2000} rows='5' className={(inputErrors.description ? 'error' : '')}
                        onChange={(e) => (setInputs((s) => ({ ...s, description: e.target.value })), setInputErrors({}))} />
                </div>
                <p className='max-info'>
                    <img src="./assets/img/icons/security-dark.svg" alt="" />
                    Maximum 2000 characters allowed
                </p>
            </div>
            <div className='attachment-files'>
                {
                    inputs.files && inputs.files.map((file, i) => {
                        return (
                            <>
                                {file.type === 'image/jpeg' || file.type === 'image/webp' || file.type === 'image/png' ?
                                    <span className='file-container'>
                                        <img className='file' src={URL.createObjectURL(file)} alt="" />
                                        <img className='file-close' src="./assets/img/icons/Group 11563.svg" alt="" />
                                        {/* <p className='file-name'>{file.name}</p> */}
                                    </span> :
                                    file.type === 'application/pdf' ?
                                        <span className='file-container'>
                                            <img className='file' src='./assets/img/icons/Group 11807.png' alt="" />
                                            <img className='file-close' src="./assets/img/icons/Group 11563.svg" alt="" />
                                            {/* <p className='file-name'>{file.name}</p> */}
                                        </span> :
                                        null
                                }
                            </>
                        )
                    })
                }
            </div>
            <div className='buttons'>
                <label className='attachment' htmlFor="upload-attachment">
                    <img src="./assets/img/icons/Icon awesome-upload.svg" alt="" />
                    <input type="file" name="" id="upload-attachment" multiple hidden
                        onChange={(e) => setInputs((s) => ({ ...s, files: [...s.files, ...Array.from(e.target.files)] }))} />
                    Add Attachments
                </label>
                <button className='next-button' onClick={onNextButtonHandle}>
                    Next
                    <img className='right' src="./assets/img/icons/Icon awesome-arrow-right.svg" alt="" />
                </button>
            </div>
        </div>
    )
}