import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Assignments from "./pages/Assignments";
import Jurisdictions from "./pages/Jurisdictions";
import ReportIncident from "./pages/ReportIncident";
import RouteHandler from "./router/routes";

function App() {
  return (
    <BrowserRouter>
      <RouteHandler />
    </BrowserRouter>
  );
}

export default App;
