import React from 'react'
import { Link } from 'react-router-dom'

export default function LinkExpired() {
    return (
        <div className='LinkExpired'>
            <img className='wrong' src="./assets/img/icons/dangerous_FILL0_wght400_GRAD0_opsz48.svg" alt="" />
            <h2>Sorry the link has Expired!</h2>
            <Link className='next-button' to={'/'}>
                <img className='arrow left' src="./assets/img/icons/Icon awesome-arrow-right.svg" alt="" />
                Back to home page
            </Link>
        </div>
    )
}
