import React from 'react'
import { Link } from 'react-router-dom'

export default function ThanksPage() {
    return (
        <div className='ThanksPage'>
            <img className='tick' src="./assets/img/icons/Icon awesome-check-circle.svg" alt="" />
            <h2>Thank You</h2>
            <p>Incident No : <strong>ESCD23001</strong></p>
            <p>You shall receive an SMS and Email with Reference ID</p>
            <Link className='next-button' to={'/'}>
                <img className='arrow left' src="./assets/img/icons/Icon awesome-arrow-right.svg" alt="" />
                Back to home page
            </Link>
        </div>
    )
}
