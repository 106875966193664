import React from 'react'

export default function Page4({ inputs, setStage, setInputs }) {
    return (
        <div className='Page4 container'>
            <section className='personal-info'>
                <h3>Personal Information</h3>
                <div className='row1'>
                    <div className='column'>
                        <div>
                            <p className="title">First Name</p>
                            <p className="value">John</p>
                        </div>
                        <div>
                            <p className="title">Last Name</p>
                            <p className="value">Doe</p>
                        </div>
                    </div>
                    <div className='column'>
                        <div>
                            <p className="title">Email</p>
                            <p className="value">johndoe@nomail.com</p>
                        </div>
                        <div>
                            <p className="title">Phone</p>
                            <p className="value">+971 55 486 73875</p>
                        </div>
                    </div>
                </div>
            </section>
            <hr />
            <section className='incident-repo'>
                <h3>Incident Report</h3>
                <div className='tab'>
                    <div className='row2'>
                        <div className='column'>
                            <div>
                                <p>Incident Type</p>
                                <p>Crimes related to the public office</p>
                            </div>
                            <div>
                                <p>Date of Incident</p>
                                <p>11 May 2022</p>
                            </div>
                        </div>
                        <div className='column'>
                            <div>
                                <p>Name of Entity</p>
                                <p>Lorem Ipsum</p>
                            </div>
                            <div>
                                <p>Is this incident reported elsewhere?</p>
                                <p>Yes</p>
                            </div>
                        </div>
                    </div>
                    <div className='row3'>
                        <p>Description</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis arcu sit amet pulvinar dapibus. Nam sagittis magna eget leo dapibus, a volutpat augue faucibus. Vestibulum ultricies ac mauris et dictum. Etiam id ornare dolor.</p>
                    </div>
                    <div className='row3'>
                        <p>Attachments</p>
                        <div className='attachment-files'>
                            {
                                inputs.files && inputs.files.map((file, i) => {
                                    return (
                                        <>
                                            {file.type === 'image/jpeg' || file.type === 'image/webp' || file.type === 'image/png' ?
                                                <span className='file-container'>
                                                    <img className='file' src={URL.createObjectURL(file)} alt="" />
                                                    {/* <p className='file-name'>{file.name}</p> */}
                                                </span> :
                                                file.type === 'application/pdf' ?
                                                    <span className='file-container'>
                                                        <img className='file' src='./assets/img/icons/Group 11807.png' alt="" />
                                                        {/* <p className='file-name'>{file.name}</p> */}
                                                    </span> :
                                                    null
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
            <div className="buttons">
                <span className='confirmation'>
                    <input type="checkbox" name="" id="confirmation" />
                    <label htmlFor="confirmation">I confirm that above information's are accurate</label>
                </span>
                <div className='buttons-group'>
                    <button className='next-button' onClick={() => setStage(3)}>
                        <img className='left arrow' src="./assets/img/icons/Icon awesome-arrow-right.svg" alt="" />
                        Edit
                    </button>
                    <button className='next-button'>
                        Submit
                        <img className='right' src="./assets/img/icons/Icon awesome-arrow-right.svg" alt="" />
                    </button>
                </div>
            </div>
        </div >
    )
}
