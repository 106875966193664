import React, { useState } from 'react'
import OTPInput, { ResendOTP } from "otp-input-react";
import { onOTPrequestValidation } from '../../utils/validation';

export default function Page2({ setStage, setInputs, inputs, setInputErrors, inputErrors }) {

    const [OTP, setOTP] = useState("");


    async function onOTPrequestHandle() {
        const isValidated = await onOTPrequestValidation({
            first_name: inputs.first_name,
            last_name: inputs.last_name,
            email: inputs.email,
            phone_number: inputs.phone_number
        }, setInputErrors)

        console.log({ isValidated })
    }

    return (
        <div className='Page2 container'>
            <section>
                <h2>Please fill in your personal information</h2>
                {/* <p>Fill the mandatory fields</p> */}
                <div className='personal-info-form container'>
                    <div className='input-group'>
                        {inputErrors.first_name && <p>{inputErrors.first_name}</p>}
                        <input type="text" placeholder='First Name*' className={(inputErrors.first_name ? 'error' : '')}
                            onChange={(e) => (setInputs((s) => ({ ...s, first_name: e.target.value })), setInputErrors({}))} />
                    </div>
                    <div className='input-group'>
                        {inputErrors.last_name && <p>{inputErrors.last_name}</p>}
                        <input type="text" placeholder='Last Name*' className={`${inputErrors.last_name ? 'error' : ''}`}
                            onChange={(e) => (setInputs((s) => ({ ...s, last_name: e.target.value })), setInputErrors({}))} />
                    </div>
                    <div className='input-group'>
                        {inputErrors.email && <p>{inputErrors.email}</p>}
                        <input type="text" placeholder='Email*' className={`${inputErrors.email ? 'error' : ''}`}
                            onChange={(e) => (setInputs((s) => ({ ...s, email: e.target.value })), setInputErrors({}))} />
                    </div>
                    <div className='input-group'>
                        {inputErrors.phone_number && <p>{inputErrors.phone_number}</p>}
                        <div className='phone'>
                            <input type="text" placeholder='+971'
                                onChange={(e) => (setInputs((s) => ({ ...s, country_code: e.target.value })), setInputErrors({}))} />
                            <input type="text" placeholder='Phone No*' className={`${inputErrors.phone_number ? 'error' : ''}`}
                                onChange={(e) => (setInputs((s) => ({ ...s, phone_number: e.target.value })), setInputErrors({}))} />
                        </div>
                    </div>
                </div>
                <div className='send-otp container'>
                    <button onClick={onOTPrequestHandle}>Send OTP</button>
                </div>
            </section>
            <section className='enter-otp'>
                <p>We have send you a One Time Password on your phone number</p>
                <p className='label'>Enter the OTP</p>
                <div className='otp-input'>
                    <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} />
                    <ResendOTP className='otp-resend' onResendClick={() => console.log("Resend clicked")}>Resend</ResendOTP>
                </div>
            </section>
            <div className='buttons'>
                <button className='next-button' onClick={() => setStage(3)}>
                    Next
                    <img className='right' src="./assets/img/icons/Icon awesome-arrow-right.svg" alt="" />
                </button>
            </div>
        </div>
    )
}
