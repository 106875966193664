import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function UpdatesPage() {

    const [details, setDetails] = useState({ files: [] })
    const [thanksModal, setThanksModal] = useState(false)

    return (
        <div className='UpdatesPage container'>
            <div className="report-details">
                <h2>Report Details</h2>
                <section className='personal-info'>
                    <div className='row1'>
                        <div className='column'>
                            <div>
                                <p className="title">First Name</p>
                                <p className="value">John</p>
                            </div>
                            <div>
                                <p className="title">Last Name</p>
                                <p className="value">Doe</p>
                            </div>
                        </div>
                        <div className='column'>
                            <div>
                                <p className="title">Email</p>
                                <p className="value">johndoe@nomail.com</p>
                            </div>
                            <div>
                                <p className="title">Phone</p>
                                <p className="value">+971 55 486 73875</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='incident-repo'>
                    <div className='tab'>
                        <div className='row2'>
                            <div className='column'>
                                <div>
                                    <p>Incident Type</p>
                                    <p>Crimes related to the public office</p>
                                </div>
                                <div>
                                    <p>Date of Incident</p>
                                    <p>11 May 2022</p>
                                </div>
                            </div>
                            <div className='column'>
                                <div>
                                    <p>Name of Entity</p>
                                    <p>Lorem Ipsum</p>
                                </div>
                                <div>
                                    <p>Is this incident reported elsewhere?</p>
                                    <p>Yes</p>
                                </div>
                            </div>
                        </div>
                        <div className='row3'>
                            <p>Description</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis arcu sit amet pulvinar dapibus. Nam sagittis magna eget leo dapibus, a volutpat augue faucibus. Vestibulum ultricies ac mauris et dictum. Etiam id ornare dolor.</p>
                        </div>
                    </div>
                </section>
            </div>
            <div className='reply'>
                <h2>Update from ESCD :</h2>
                <p>Please Upload your valid Emirates ID</p>
            </div>
            <div className='attachment-files'>
                {
                    details.files && details.files.map((file, i) => {
                        return (
                            <>
                                {file.type === 'image/jpeg' || file.type === 'image/webp' || file.type === 'image/png' ?
                                    <span className='file-container'>
                                        <img className='file' src={URL.createObjectURL(file)} alt="" />
                                        <img className='file-close' src="./assets/img/icons/Group 11563.svg" alt="" />
                                        {/* <p className='file-name'>{file.name}</p> */}
                                    </span> :
                                    file.type === 'application/pdf' ?
                                        <span className='file-container'>
                                            <img className='file' src='./assets/img/icons/Group 11807.png' alt="" />
                                            <img className='file-close' src="./assets/img/icons/Group 11563.svg" alt="" />
                                            {/* <p className='file-name'>{file.name}</p> */}
                                        </span> :
                                        null
                                }
                            </>
                        )
                    })
                }
            </div>
            <div className="buttons">
                <label className='attachment' htmlFor="upload-attachment">
                    <img src="./assets/img/icons/Icon awesome-upload.svg" alt="" />
                    <input type="file" name="" id="upload-attachment" multiple hidden
                        onChange={(e) => setDetails((s) => ({ ...s, files: [...s.files, ...Array.from(e.target.files)] }))} />
                    Add Attachments
                </label>
                <button className='next-button' onClick={() => setThanksModal(true)}>
                    Submit
                    <img className='right' src="./assets/img/icons/Icon awesome-arrow-right.svg" alt="" />
                </button>
            </div>
            <Modal show={thanksModal} className='thank-you-modal'>
                <Modal.Body>
                    <img className='tick' src="./assets/img/icons/Icon awesome-check-circle.svg" alt="" />
                    <h2>Thank You</h2>
                    <Link className='next-button' to={'/'}>
                        <img className='arrow left' src="./assets/img/icons/Icon awesome-arrow-right.svg" alt="" />
                        Back to home page
                    </Link>
                </Modal.Body>
            </Modal>
        </div>
    )
}
