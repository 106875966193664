import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import About from '../pages/About';
import Assignments from '../pages/Assignments';
import Contact from '../pages/Contact';
import Home from '../pages/Home';
import Jurisdictions from '../pages/Jurisdictions';
import NotFound from '../pages/NotFound';
import ReportIncident from '../pages/ReportIncident';
import i18next from 'i18next';

const RouteHandler = () => {
  const [language, setLanguage] = useState('')
  useEffect(() => {
    const lang = localStorage.getItem('lang')
    setLanguage(lang)
  }, [localStorage.getItem('lang')])
  return (
    console.log(language,"language"),
    <>
      <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path="/en" element={<Home />}  />
      <Route path="/ar" element={<Home />} />

      <Route path="/en/about" element={<About />} />
      {/* <Route path="/ar/about" element={<About />} /> */}

      <Route path="/en/contact" element={<Contact />} />
      {/* <Route path="/ar/contact" element={<Contact />} /> */}

      <Route path="/en/assignments" element={<Assignments />} />
      {/* <Route path="/ar/assignments" element={<Assignments />} /> */}

      <Route path="/en/jurisdictions" element={<Jurisdictions />} />
      {/* <Route path="/ar/jurisdictions" element={<Jurisdictions />} /> */}

      <Route path="/en/report_incident" element={<ReportIncident />} />
      {/* <Route path="/ar/report_incident" element={<ReportIncident />} /> */}
      <Route path="*" element={<NotFound/>} />
    </Routes>
    </>

  );
}

export default RouteHandler;
