import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper";
import Footer from '../components/Footer';
import Aos from "aos";
import "aos/dist/aos.css";
import { getAbout, getAboutValues, getVisionMission } from '../redux/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { AboutValues_details, About_details, visionMision_lists } from '../redux/commonReducer';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from '../constants/configuration';
function About() {
    const { t } = useTranslation();
    const [language, setLanguage] = useState('')
    const dispatch = useDispatch()
  // 
  const [title, setTitle] = useState("");

  useEffect(() => {
    document.title = title;
  }, [title]);

    // DATAS
    const visionMisionLists = useSelector(visionMision_lists)
    const AboutDetails = useSelector(About_details)
    const ValuesDetails = useSelector(AboutValues_details)

    useEffect(() => {
        dispatch(getVisionMission())
        dispatch(getAbout())
        dispatch(getAboutValues())
    }, [])

    useEffect(() => {
        setTitle(t('html_title.about'))
        const lang = localStorage.getItem('lang')
        setLanguage(lang)
    }, [localStorage.getItem('lang')])


    useEffect(() => {
        const lang = localStorage.getItem('lang')
        setLanguage(lang)
        Aos.init({ duration: 2000 });
    }, []);
    return (
        <>
            <Header />
            <section className="innerpage__header">
                <div className="container sm-pad-0">
                    <div className="banner__image">
                        {
                            AboutDetails?.map((ele) => {
                                return (
                                    <img src={API_BASE_URL + 'about/' + ele?.banner_name} className='w-100 about-img' alt="" />
                                )
                            })
                        }

                        <div className="contents">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="header__title">
                                        <h2>{t('main_title.about')}</h2>
                                    </div>
                                </div>

                                {
                                    visionMisionLists?.map((ele, i) => {
                                        return (
                                            <>
                                                <div class="col-md-4">
                                                    <div className="vision__mission">
                                                        <h4>{language == 'en' ? ele?.mission_title_en : ele?.mission_title_ar}</h4>
                                                        <p dangerouslySetInnerHTML={{ __html: language == 'en' ? ele?.mission_detail_en : ele?.mission_detail_ar }}></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div className="vision__mission">
                                                        <h4>{language == 'en' ? ele?.vision_title_en : ele?.vision_title_ar}</h4>
                                                        <p dangerouslySetInnerHTML={{ __html: language == 'en' ? ele?.vision_detail_en : ele?.vision_detail_ar }}></p>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="economy__aboutus bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 p-0">
                            {
                                AboutDetails?.map((ele) => {
                                    return (
                                        <div className="about__image" data-aos="fade-right">
                                            <img src={API_BASE_URL + 'about/' + ele?.image_name} className='w-100' alt="" />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-md-6 p-5">
                            {
                                AboutDetails?.map((ele) => {
                                    return (
                                        <div className="about__contents" data-aos="fade-left">
                                            <div className="icon"><img src="./assets/img/icons/about.svg" alt="" /></div>
                                            <h6>{t('main_title.about')}</h6>
                                            <h2>{language == 'en' ? ele?.title_en : ele?.title_ar}</h2>
                                            <p dangerouslySetInnerHTML={{ __html: language == 'en' ? ele?.detail_en : ele?.detail_ar }}></p>
                                        </div>
                                    )
                                })
                            }
                            <div className="values__section">
                                <h3>{t('main_title.our_values')}</h3>
                                <div className="slider__section">
                                    <div className="arrows">
                                        <div className="swiper-button image-swiper-button-prev">
                                            <i class="fas fa-chevron-left"></i>
                                        </div>
                                        <div className="swiper-button image-swiper-button-next">
                                            <i class="fas fa-chevron-right"></i>
                                        </div>
                                    </div>
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        loop={true}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={{
                                            nextEl: ".image-swiper-button-next",
                                            prevEl: ".image-swiper-button-prev",
                                            disabledClass: "swiper-button-disabled"
                                        }}
                                        modules={[Pagination, Navigation]}
                                        className="mySwiper"
                                    >
                                        {
                                            ValuesDetails?.map((ele) => {
                                                return (
                                                    <SwiperSlide>
                                                        <div className="slider__contents">
                                                            <h4>{language == 'en' ? ele?.title_en : ele?.title_ar}</h4>
                                                            <p dangerouslySetInnerHTML={{ __html: language == 'en' ? ele?.detail_en : ele?.detail_ar }}></p>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default About