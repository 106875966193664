import axios from 'axios';
import { updateRedux } from './commonReducer';
import { API_BASE_URL } from "../constants/configuration";
import { Toast } from 'bootstrap';
import { toast } from 'react-toastify';

export const getBanner = (callback) => (dispatch) => {
    axios.get(`${API_BASE_URL}web/getBanner`).then((res)=>{
      if(res.data.status){
        dispatch(updateRedux({ key: "banner_lists", value: res.data.banner }))
        callback(res.data.banner)
      }else {
        Toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
        console.log(err.response,"")
    })
  }

  export const getVisionMission = (callback) => (dispatch) => {
    axios.get(`${API_BASE_URL}web/getVisionMission`).then((res)=>{
      if(res.data.status){
        dispatch(updateRedux({ key: "visionMision_lists", value: res.data.visionMission }))
        callback(res.data.visionMission)
      }else {
        Toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
        console.log(err.response,"6")
    })
  }

  export const getHome = (callback) => (dispatch) => {
    axios.get(`${API_BASE_URL}web/getHome`).then((res)=>{
      if(res.data.status){
        dispatch(updateRedux({ key: "getHome_lists", value: res.data.home }))
        callback(res.data.home)
      }else {
        Toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
        console.log(err.response,"6")
    })
  }

  export const getRolesAssign = (callback) => (dispatch) => {
    axios.get(`${API_BASE_URL}web/getRolesAssign`).then((res)=>{
      if(res.data.status){
        dispatch(updateRedux({ key: "rolesAssign_lists", value: res.data.rolesAssign }))
        callback(res.data.rolesAssign)
      }else {
        Toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
        console.log(err.response)
    })
  }

  export const getAbout = () => (dispatch) => {
    axios.get(`${API_BASE_URL}web/getAbout`).then((res)=>{
      if(res.data.status){
        dispatch(updateRedux({ key: "About_details", value: res.data.about }))
      }else {
        Toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
        console.log(err.response,"6")
    })
  }


  export const getAboutValues = () => (dispatch) => {
    axios.get(`${API_BASE_URL}web/getAboutValues`).then((res)=>{
      if(res.data.status){
        dispatch(updateRedux({ key: "AboutValues_details", value: res.data.aboutValues }))
      }else {
        Toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
        console.log(err.response,"6")
    })
  }
  

  export const getContactInfo = () => (dispatch) => {
    axios.get(`${API_BASE_URL}web/getContactInfo`).then((res)=>{
      if(res.data.status){
        dispatch(updateRedux({ key: "contactInfo_details", value: res.data.contactInfo }))
      }else {
        Toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err)=>{
        console.log(err.response,"6")
    })
  }
  

  export const submitContactMessages = (formData, callback) => () => {
    axios.post(`${API_BASE_URL}web/submitContactMessages`, formData).then(res => {
    if (res.data.status == true) {
      if(callback) {
        callback(res.data.status)
      }
    }else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if(callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getJurisdictionAuthorities = () => (dispatch) => {
  axios.get(`${API_BASE_URL}web/getJurisdictionAuthorities`).then((res)=>{
    if(res.data.status){
      dispatch(updateRedux({ key: "jurisdictionAuthorities_details", value: res.data.jurisdictionAuthorities }))
    }else {
      Toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err)=>{
      console.log(err.response,"6")
  })
}